.xbp-button-borderless {
  button {
    border: none !important;
    box-shadow: none !important;
  }
}

.text-color-subdued {
  color: var(--color-text-subdued);
}
