@use "/node_modules/@gls/platform-ui/src/themes/default/styles/global-styles";
@use "/src/app/shared/xbp-commons/styles/xbp-styles";
@include global-styles.global-styles-light-theme; //imports both dark and light tokens but light ones are default

body {
  background-color: var(--color-background-canvas);
}
.toolbar-spacer {
  flex: 1 1 auto;
}

a.title {
  color: white;
  text-decoration: none;
}

@font-face {
  font-family: "NewsonGLS";
  src: url("./assets/NewsonGLS-WEB-Regular.woff") format("woff");
}

.main-content.scrollable-area {
  scrollbar-gutter: stable;
}

// TODO @gls/platform-ui fix - support for multiline
gls-toast {
  .toast-container {
    .toast {
      .toast-message {
        white-space: pre-line;
      }
    }
  }
}
